export default [
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/e-commerce/shop',
    name: 'lagermeister-shop',
    component: () =>
      import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/e-commerce/checkout',
    name: 'lagermeister-checkout',
    component: () =>
      import(
        '@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'
      ),
    meta: {
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/e-commerce/:id',
    name: 'apps-e-commerce-product-details',
    component: () =>
      import(
        '@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'
      ),
    meta: {
      pageTitle: 'Lagerdetails',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Lager',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Lagerdetails',
          active: true,
        },
      ],
    },
  },
  {
    path: '/e-commerce/docupload',
    name: 'lagermeister-docupload',
    component: () =>
      import('@/views/apps/e-commerce/document-upload/LMSDocUpload.vue'),
    meta: {
      pageTitle: 'Dokumentenablage',
      contentClass: 'ecommerce-application',
    },
  },
]
