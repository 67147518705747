const state = {
  cart: JSON.parse(localStorage.getItem('cart')) || [],
}

const mutations = {
  addToCart(state, product) {
    state.cart.push(product)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  removeFromCart(state, productIndex) {
    state.cart.splice(productIndex, 1)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  UPDATE_CART_ENTRIES(state, payload) {
    state.cart = payload
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  UPDATE_QUANTITY(state, payload) {
    const item = state.cart.find(item => item.id === payload.id)
    if (item) {
      item.qty = payload.qty
      localStorage.setItem('cart', JSON.stringify(state.cart))
    }
  },
}

const actions = {
  getCartEntries({ commit }) {
    commit('UPDATE_CART_ENTRIES', [])
  },
  addToCart({ commit }, product) {
    commit('addToCart', product)
  },
  removeFromCart({ commit }, productIndex) {
    commit('removeFromCart', productIndex)
  },
  updateProductQuantity({ commit }, { productId, quantity }) {
    commit('UPDATE_QUANTITY', { productId, quantity })
  },
  cleanCart({ commit }) {
    commit('UPDATE_CART_ENTRIES', [])
  },
}

const getters = {
  cart(state) {
    return state.cart
  },
  cartItemCount(state) {
    return state.cart.length
  },
}

const cartModule = {
  state,
  mutations,
  actions,
  getters,
}

export default cartModule
