import API from '@/service/api'

const state = {}

const mutations = {}

const actions = {
  postUserShopUploads({}, payload) {
    return API()
      .post('/shop-uploads', payload)
      .then(response => {
        console.log('postUserShopUploads OK')
        return response.data
      })
      .catch(error => {
        console.log('Error:  postUserShopUploads')
        console.log(error)
        return error
      })
  },
  addFile({}, payload, config) {
    return API()
      .post('/upload', payload, config)
      .then(response => {
        console.log('addFile OK')
        return response.data
      })
      .catch(error => {
        console.log('Error: addFile')
        console.log(error)
        return error
      })
  },

  addAttachment({}, payload) {
    return API()
      .post('/attachements', payload)
      .then(response => {
        console.log('addAttachment OK')
        return response.data
      })
      .catch(error => {
        console.log('Error: addAttachment')
        console.log(error.response.data)
        return error
      })
  },
}

const getters = {}

const shopUploadsModule = {
  state,
  mutations,
  actions,
  getters,
}

export default shopUploadsModule
