import API from '@/service/api'

const state = {
  locationsEntries: [],
  selectedLocation: null,
}

const mutations = {
  UPDATE_LOCATIONS_ENTRIES(state, payload) {
    state.locationsEntries = payload
  },
  UPDATE_SELECTED_LOCATIONS(state, location) {
    state.selectedLocation = location
  },
}

const actions = {
  getLocationsEntries({ commit }) {
    return API()
      .get('/locations')
      .then(response => {
        console.log('getLocationEntries OK')
        commit('UPDATE_LOCATIONS_ENTRIES', response.data)
      })
      .catch(error => {
        console.log('Error: getLocationEntries')
        console.log(error.response.data)
        return error
      })
  },
  updateSelectedLocation({ commit }, location) {
    commit('UPDATE_SELECTED_LOCATIONS', location)
  },
}

const getters = {
  locationsEntries: state => state.locationsEntries,
  locationById: state => id =>
    state.locationsEntries.find(locationsEntry => locationsEntry.id === id),
  selectedLocation: state => state.selectedLocation,
}

const locationsModule = {
  state,
  mutations,
  actions,
  getters,
}

export default locationsModule
