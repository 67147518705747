import API from '@/service/api'

const state = {}

const mutations = {}

const actions = {
  checkEmailAndOffer({}, payload) {
    const params = new URLSearchParams(payload).toString()
    return API()
      .get(`/offers/check?${params}`)
      .then(response => {
        console.log('Check Offer OK')
        return response.data
      })
      .catch(error => {
        console.log('Error: Check Offer')
        console.log(error)
        return error
      })
  },
  checkEmailAndOrder({}, payload) {
    const params = new URLSearchParams(payload).toString()
    return API()
      .get(`/orders/check?${params}`)
      .then(response => {
        console.log('Check Offer OK')
        return response.data
      })
      .catch(error => {
        console.log('Error: Check Offer')
        console.log(error)
        return error
      })
  },
}

const getters = {}

const checkOfferOrderModule = {
  state,
  mutations,
  actions,
  getters,
}

export default checkOfferOrderModule
