import API from '@/service/api'

const state = {}

const mutations = {}

const actions = {
  addInquiry({}, payload) {
    return API()
      .post('/inquiries', payload)
      .then(response => {
        console.log('addInquiry OK')
        console.log(response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: addInquiry')
        console.log(error)
        return error
      })
  },
}

const getters = {}

const inquiryModule = {
  state,
  mutations,
  actions,
  getters,
}

export default inquiryModule
