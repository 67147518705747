import API from '@/service/api'

const state = {
  storageTypesEntries: [],
}

const mutations = {
  UPDATE_STORAGE_TYPES_ENTRIES(state, payload) {
    state.storageTypesEntries = payload
  },
}

const actions = {
  getStorageTypesEntries({ commit }) {
    return API()
      .get('/storage-types')
      .then(response => {
        console.log('getStorageTypesEntries OK')
        commit('UPDATE_STORAGE_TYPES_ENTRIES', response.data)
        return response.data
      })
      .catch(error => {
        console.log('Error: getStorageTypesEntries')
        console.log(error.response.data)
        return error
      })
  },
}

const getters = {
  getStorageTypes: state => state.storageTypesEntries,
  getStorageTypesById: state => id =>
    state.storageTypesEntries.find(storageType => storageType.id === id),
}

const storageModule = {
  state,
  mutations,
  actions,
  getters,
}

export default storageModule
