import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import cart from './cart'
import checkOfferOrder from './checkEmailAndOfferOrder'
import inquiry from './inquiry'
import location from './location'
import shopUploads from './shopUpload'
import storageTypes from './storage'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    storageTypes,
    inquiry,
    location,
    cart,
    shopUploads,
    checkOfferOrder,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
